import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import styled from 'styled-components';
import supportMessages from '../intl/layout/supportMessages';
import renaultCar2 from '../images/car2.png';
import mainPageMessages from '../intl/home/mainPageMessages';
import { Field, reduxForm } from 'redux-form';
import { PrimaryButton } from './common/Button';
import { saveProfile } from '../actions/actionSupport';
import Header from './Header';
import Countdown from './Countdown';
import Loader from './Loader';

const Img = styled.img`
    height: 35%;
    width: 35%;
`;
const List = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0;
`;
const Form = styled.form`
    width: 35%;
    margin: auto;
    margin-top: 10px;
`;
const StyledLoader = styled.div`
 display: inline-block;
`;
Img.displayName = 'Img';

class ProfileSwitchPage extends Component {
    componentWillMount () {
        const {userDetail: { ipn }} = this.props;
        this.props.initialize({ ipn });
    }

    handleSubmit = async ({ipn}) => {
        const {saveProfile, userDetail: {ipn: currentIpn}} = this.props
        if(currentIpn !== ipn) {
            saveProfile({ipn})
        } else {
            window.location.reload()
        }
        sessionStorage.setItem("activeProfileSession", ipn)
    }

    render() {
        const {userDetail: {profiles = []}, handleSubmit, initiateCountdown, count, isLoading} = this.props;

        return <>
            <Header />
            <div style={{ height: 4, width: 83, backgroundColor: "#fd7e14", marginLeft: 35, marginTop: 10, marginBottom: 20 }}></div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <h1 className="text-center"><FormattedMessage {...mainPageMessages.TITLE} /></h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="text-center pt-3">
                            <Img src={renaultCar2}/>
                        </div>
                    </div>
                </div>
                {initiateCountdown ?
                    <div className="d-flex flex-column align-items-center mt-3">
                        <Countdown initiateCountdown={count}/>
                        <FormattedMessage {...supportMessages.PROFILE_CHANGE_INITIATED} />
                    </div> :
                    <Form onSubmit={handleSubmit(this.handleSubmit)}>
                        <List>
                            {profiles.map(p => <li>
                                <label>
                                    <Field
                                        name="ipn"
                                        component="input"
                                        type="radio"
                                        value={p.ipn}
                                        disabled={isLoading || initiateCountdown}
                                    />
                                    {" " + Object.keys(p).map((key) => p[key]).join(" | ")}
                                </label>
                            </li>)}
                        </List>
                        <PrimaryButton style={{margin: 10, marginLeft: 0}} type="submit" className="btn" disabled={isLoading || initiateCountdown} >Select user</PrimaryButton>
                        {isLoading &&
                        <StyledLoader className="pl-2 py-auto">
                            <Loader isSmall/>
                        </StyledLoader>
                        }
                    </Form>
                }
            </div>
        </>
    }
}

const mapStateToProps = state => ({
    userDetail: state.profile.userDetail,
    initiateCountdown: state.supportModal.initiateCountdown,
    count: state.supportModal.count,
    isLoading: state.supportModal.isLoading,
});

export default  reduxForm({
    form: 'initialProfileSwitchForm',
})(connect(mapStateToProps, {saveProfile})(ProfileSwitchPage));
